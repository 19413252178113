
import { Button, IconButton, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,Typography, Avatar, Divider, Alert } from '@mui/material'
import { Box} from '@mui/system';
import {IconEye, IconX,  IconBubbleText, IconClockEdit, IconSend2 } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

const Requests = () => {
    const [isSubmitting, setisSubmitting] = useState(false)
    const [corrections, setcorrections] = useState(false)
    const [Comments, setComments] = useState(false)
    const [ProjectId, setProjectId] = useState(false)
    const [CommentId, setCommentId] = useState(false)
    const [data, setdata] = useState(false)
    const [error, setError] = useState(false)
    const [Commentsdata, setCommentdata] = useState(false)
    const [CommentHistory, setCommentHistory] = useState([]);
    const [commenttext, setcommenttext] = useState("");
    const [activeTab, setActiveTab] = useState("corrections");

    const handleTab = (tabName) => {
        setActiveTab(tabName);
    };
    const columns = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Project Name</Typography>,
            selector: (row) => row.meta, cell: row => row.project_name
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Stake Holder</Typography>,
            selector: (row) => row.meta, cell: row => row.team_name
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
            selector: (row) => row.meta, cell: row => row.stakeholder_designation
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Corrections</Typography>,
            selector: (row) => row.meta,
            cell: row => (
                <>
                    <div
                        className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
                        role="button"
                        onClick={() => setcorrections(true)}
                        style={{ marginRight: '10px' }} 
                    >
                        <IconEye color={"#182286"} />
                    </div>
                </>
            ),
        }

    ];
    const columns1 = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Date</Typography>,
            selector: (row) => row.meta, cell: row => row.date
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Project Name</Typography>,
            selector: (row) => row.meta, cell: row => row.project_name
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Stake Holder</Typography>,
            selector: (row) => row.meta, cell: row => row.stakeholder
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
            selector: (row) => row.meta, cell: row => row.designation
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Comments</Typography>,
            selector: (row) => row.meta,
            cell: row => (
                <>
                    <div
                        className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
                        role="button"
                        onClick={() => { setComments(true); getCommentHistory(row); setProjectId(row.proj_id); setCommentId(row.com_id) }}
                        style={{ marginRight: '10px' }}
                    >
                        <IconEye color={"#182286"} />
                    </div>
                </>
            ),
        }

    ];
    const getComments = async (data) => {
        try {
            const response = await axios.get("/apiV1/project-comments/");
            setCommentdata(response.data)
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    const getCommentHistory = async (data) => {
        try {
            const response = await axios.get(`/apiV1/view-comment/?project_id=${data.proj_id}&commented_on_id=${data.com_id}`);
            if (response.status == 200) {
                setCommentHistory(response.data)
            }

        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    const getUser = async (data) => {
        try {
            const response = await axios.get("/apiV1/raise-request/");
            console.log(response,'responseresponseresponseresponse');
            
            setdata(response.data)
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    useEffect(() => {
        getUser()
        getComments()
    }, [])
    const messages = [
        { id: 1, text: "Hello! How are you?", sender: "left", avatar: "A" },
        { id: 2, text: "I'm good, thank you! How about you?", sender: "right", avatar: "B" },
        { id: 3, text: "Doing well, thanks for asking!", sender: "left", avatar: "A" },
    ];
    const handlePostComment = async () => {
        if (commenttext.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            commented_on: CommentId,
            comment: commenttext,
            project: ProjectId
        };

        try {
            const response = await axios.post("/apiV1/comments/", requestData);
            console.log(response, 'responseresponse');
            if (response.status === 201) {
                setcommenttext('')
                setError(false);
                setComments(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            // setBackendError(error.response.data.detail);

        }
    };
    const handleCommentChange = (event) => {
        setcommenttext(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    return (
        <>
            <Card sx={{ display: 'flex', flexDirection: 'column', p: 5, margin: "0px 0px 0px 9px", position: 'relative' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box>
                            <Button
                                onClick={() => handleTab('corrections')}
                                variant="contained"
                                sx={{
                                    backgroundColor: activeTab === 'corrections' ? '#5a1bc2' : '#fff',
                                    color: activeTab === 'corrections' ? '#fff' : '#000',
                                }}
                            >
                                Corrections <IconClockEdit sx={{ ml: 2 }} />
                            </Button>
                        </Box>
                        <Box sx={{ gap: 2 }}>
                            <Button
                                onClick={() => handleTab('comments')}
                                variant="contained"
                                sx={{
                                    backgroundColor: activeTab === 'comments' ? '#5a1bc2' : '#fff',
                                    color: activeTab === 'comments' ? '#fff' : '#000', gap: 1
                                }}
                            >
                                Comments <IconBubbleText sx={{ ml: 2 }} />
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        {activeTab == "corrections" ?
                            <DataTable
                                columns={columns}
                                data={data}
                                fixedHeader
                                highlightOnHover
                                persistTableHead
                                paginationServer
                                fixedHeaderScrollHeight='500px'
                            /> :
                            <DataTable
                                columns={columns1}
                                data={Commentsdata}
                                fixedHeader
                                highlightOnHover
                                persistTableHead
                                paginationServer
                                fixedHeaderScrollHeight='500px'
                            />
                        }
                    </Box>

                </CardContent>
            </Card>

            <Dialog open={corrections} onClose={() => setcorrections(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Corrections
                    <IconButton
                        onClick={() => setcorrections(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                        {messages.map((message) => (
                            <Box
                                key={message.id}
                                display="flex"
                                justifyContent={message.sender === "right" ? "flex-end" : "flex-start"}
                                alignItems="center"
                                gap={2}
                            >
                                {message.sender === "left" && (
                                    <Avatar>{message.avatar}</Avatar>
                                )}
                                <Box
                                    sx={{
                                        backgroundColor: message.sender === "right" ? "#e3f2fd" : "#f0f0f0",
                                        color: message.sender === "right" ? "#0d47a1" : "#000",
                                        borderRadius: 2,
                                        padding: "8px 16px",
                                        maxWidth: "70%",
                                    }}
                                >
                                    <Typography>{message.text}</Typography>
                                </Box>
                                {message.sender === "right" && (
                                    <Avatar>{message.avatar}</Avatar>
                                )}
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => alert('Button clicked!')}
                        >
                            <IconSend2 />
                        </Button>
                    </div>


                    <Divider />
                    <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#6dc308' }}>
                            Approve
                        </Button>
                        <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }}>
                            Reject
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>






            <Dialog open={Comments} onClose={() => setComments(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Comments
                    <IconButton
                        onClick={() => setComments(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                        {CommentHistory.map((message) => (
                            <Box
                                key={message.id}
                                display="flex"
                                justifyContent={message.sent ? "flex-end" : "flex-start"}
                                alignItems="center"
                                gap={2}
                                sx={{ mb: 2 }} >
                                {!message.sent && (
                                    <Avatar>{message.avatar}</Avatar>
                                )}
                                <Box
                                    sx={{
                                        backgroundColor: message.sent ? "#e3f2fd" : "#f0f0f0",
                                        color: message.sent ? "#0d47a1" : "#000",
                                        borderRadius: 2,
                                        padding: "8px 16px",
                                        maxWidth: "70%",
                                        wordWrap: "break-word", 
                                    }}
                                >
                                    <Typography>{message.comment}</Typography>
                                </Box>
                                {message.sent && (
                                    <Avatar>{message.avatar}</Avatar>
                                )}
                            </Box>
                        ))}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={commenttext}
                            onChange={handleCommentChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        {/* <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handlePostComment()}
                        >
                           <IconSend2/>
                        </Button> */}
                    </div>

                    {error && <Alert severity='error'>{error ? error : null}</Alert>}
                    <Divider />
                    <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" onClick={() => handlePostComment()}>
                            Send
                        </Button>

                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Requests
