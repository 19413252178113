import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  ButtonBase,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IconBell, IconLogout, IconMessage, IconRefresh } from '@tabler/icons-react';
import axios from 'axios';

import breadcrumbImg from '../../Images/user-1.jpg';

const Breadcrumb = () => {
  const navigate = useNavigate();

  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const [anchorElAvatar, setAnchorElAvatar] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [messages, setMessages] = useState([]);
  const reactcolors = ['#fed466', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba'];

  const handleOpenMessage = (event) => {
    setAnchorElMessage(event.currentTarget);
    setOpenMessage(true);
    setAnchorElAvatar(null);
    setOpenAvatar(false);
  };

  const handleCloseMessage = () => {
    setAnchorElMessage(null);
    setOpenMessage(false);
  };

  const handleOpenAvatar = (event) => {
    setAnchorElAvatar(event.currentTarget);
    setOpenAvatar(true);
    setAnchorElMessage(null);
    setOpenMessage(false);
  };

  const handleCloseAvatar = () => {
    setAnchorElAvatar(null);
    setOpenAvatar(false);
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get('/apiV1/notifications/');
      setMessages(response.data);
    } catch (error) {
      console.error(
        'Error fetching notifications:',
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (openMessage) {
      getNotifications();
    }
  }, [openMessage]);

  const onNotificationClick = async (index) => {
    try {
      const response = await axios.get(`/apiV1/notifications/?index=${index}`);
      if (response.status === 200) {
        getNotifications();
      }
    } catch (error) {
      console.error(
        'Error fetching notification details:',
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user-info');
    sessionStorage.removeItem('AdminToken');
    navigate('/');
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: '#fff',
        boxShadow:
          'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        borderRadius: (theme) => theme.shape.borderRadius / 4,
        p: '6px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Left Section (Breadcrumb or Title) */}
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        display="flex"
        alignItems="center"
        sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
      >
        <Typography
          variant="h6"
          sx={{
            color: '#5a1bc2',
            fontWeight: 'bold',
            textAlign: { xs: 'center', sm: 'left' },
          }}
        >
          {/* {title} */}
        </Typography>
      </Grid>

      {/* Right Section (Icons and Avatar) */}
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        display="flex"
        alignItems="center"
        sx={{
          justifyContent: { xs: 'center', sm: 'flex-end' },
          mt: { xs: 2, sm: 0 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 2,
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
          }}
        >
          <IconMessage
            size={42}
            onClick={handleOpenMessage}
            color="#5a1bc2"
            style={{ cursor: 'pointer' }}
          />
          <Box sx={{ position: 'relative', ml: 2 }}>
            <img
              src={breadcrumbImg}
              alt="Avatar"
              onClick={handleOpenAvatar}
              style={{ maxWidth: '50px', borderRadius: '50%', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Grid>

      {/* Message Menu */}
      <Menu
        anchorEl={anchorElMessage}
        open={openMessage}
        onClose={handleCloseMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: 1, padding: '0px' }}
      >
        <Box sx={{ width: '100%', maxWidth: 400, boxShadow: 3, p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 16px',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#7a6ac3' }}>
              Notifications
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  color: '#7a5bf6',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: 'none',
                  border: 'none',
                  padding: 0,
                  '&:hover': { backgroundColor: 'transparent', color: '#6747cc' },
                }}
                onClick={getNotifications}
              >
                <IconRefresh />
              </Button>
              <Typography variant="subtitle2" sx={{ fontSize: '14px', color: '#5a1bc2' }}>
                <IconBell />
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: '3px' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#5a1bc2', borderRadius: '10px' },
              '&::-webkit-scrollbar-track': { backgroundColor: '#f0f0f0' },
            }}
          >
            {messages.map((notification, index) => {
              const firstLetter = notification.created_by.charAt(0).toUpperCase();
              return (
                <ButtonBase
                  key={index}
                  onClick={() => onNotificationClick(index)}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    textAlign: 'left',
                    padding: '10px 16px',
                    borderBottom: '1px solid #e0e0e0',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      backgroundColor: reactcolors[index % reactcolors.length],
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '18px',
                      textAlign: 'center',
                      marginRight: 2,
                    }}
                  >
                    {firstLetter}
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {notification.created_by}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {notification.description}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {notification.time_difference} ago
                    </Typography>
                  </Box>
                </ButtonBase>

              );
            })}
          </Box>

          {/* View All Notifications Button */}
          <Box sx={{ padding: '10px 16px', textAlign: 'center' }}>
            <Button variant="contained" sx={{ width: '100%', bgcolor: '#7b5af8', color: 'white' }}>
              View all notifications
            </Button>
          </Box>
        </Box>
      </Menu>

      {/* Avatar Menu */}
      <Menu
        anchorEl={anchorElAvatar}
        open={Boolean(anchorElAvatar)}
        onClose={handleCloseAvatar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: 2 }}
      >
        <MenuItem onClick={handleLogout}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Logout
            <IconLogout />
          </Box>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default Breadcrumb;
