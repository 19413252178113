import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, CircularProgress, TextField, Button } from "@mui/material";
import axios from "axios";

const ViewGeneratedDoc = () => {
    const [loader, setLoader] = useState(false);
    const [projectNames, setprojectNames] = useState([]);
    const [data, setData] = useState('')
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    const id = params.get("id");
    const namefromurl = params.get("name");
    const docName = params.get('docName')
    const Doctype = params.get('docType')
    const result = data[docName] || "Document not found";
    const getProjects = async () => {
        try {
            setLoader(true)
            const response = await axios.get(`/apiV1/project/${id}/generate-master-input/?master_input_name=${namefromurl}`)
            if (response.status == 200) {
                setData(response.data.data)
                setprojectNames(Object.keys(response.data.data))
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getProjects()
    }, [])
    const handleDownload = async () => {
        try {
            const Data = {
                brd_html: result,
                doc_type: docName,
            };

            setLoader(true);

            const response = await axios.post(`/apiV1/download-document/`, Data, {
                responseType: "blob",
            });

            if (response.status === 200) {
                let fileType = "";
                let fileExtension = "";

                switch (Doctype) {
                    case "pdf":
                        fileType = "application/pdf";
                        fileExtension = "pdf";
                        break;
                    case "docx":
                        fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                        fileExtension = "docx";
                        break;
                    case "xlsx":
                        fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                        fileExtension = "xlsx";
                        break;
                    default:
                        throw new Error("Unsupported document type");
                }
                const blob = new Blob([response.data], { type: fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${docName || "document"}.${fileExtension}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log(`${fileExtension.toUpperCase()} file downloaded successfully!`);
            } else {
                console.error("Failed to download file: Unexpected response status", response.status);
            }

            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error("Error downloading file:", error);
        }
    };



    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                padding: "30px",
                backgroundColor: "#fff",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                margin: "0px 0px 0px 9px",
            }}
        >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "50%" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#8069e5" }}>
                    View of BRD File
                </Typography>

                <Box sx={{ mb: 2, position: 'relative' }}>
                    {loader && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                            }}
                        >
                            <CircularProgress size={24} />
                        </Box>
                    )}

                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '100%',
                            maxWidth: '1500px',
                        }}
                    >
                        <div
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                minHeight: '150px',
                                height: '300px',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                overflowY: 'auto',
                                opacity: loader ? 0.5 : 1,
                                pointerEvents: loader ? 'none' : 'auto',
                            }}
                            dangerouslySetInnerHTML={{ __html: result }}
                        />
                    </div>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleDownload}
                    disabled={loader}
                >
                    Export
                </Button>
            </Grid>
        </Grid>
    );
};

export default ViewGeneratedDoc;
