import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Avatar, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IconEye } from '@tabler/icons-react';
import avatar1 from '../../Images/user-1.jpg'
import avatar2 from '../../Images/user-2.jpg'
import avatar3 from '../../Images/user-3.jpg'
import avatar4 from '../../Images/user-4.jpg'
const MyProjects = () => {
    const [projects, setprojects] = useState([]);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const [loading, setLoading] = useState(true);
    const groupAvatars = [avatar1, avatar2, avatar3, avatar4];
    const navigate = useNavigate();
    const reactcolors = ['#fed466', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba']
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [alignment, setAlignment] = useState('Assigned');
    const [filteredProjects, setFilteredProjects] = useState([]);


    const getProjects = async () => {
        try {
            setLoading(true)
            const response = await axios.get('apiV1/projects/')
            if (response.status == 200) {
                console.log(response);
                setprojects(response.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getProjects()
    }, [])


    useEffect(() => {
        if (alignment === 'Assigned') {
            const assignedProjects = projects.filter(project => project.can_access === true);
            setFilteredProjects(assignedProjects);
        } else {
            const unassignedProjects = projects.filter(project => project.can_access === false);
            setFilteredProjects(unassignedProjects);
        }
    }, [projects, alignment]);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px", justifyContent: "space-between" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
                {userInfo.is_admin === false ?
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton
                            value="Assigned"
                            sx={{
                                borderRadius: "10px 0px 0px 10px", maxHeight: "45px", mt: 1,
                                backgroundColor: alignment === 'Assigned' ? '#5a1bc3' : 'transparent',
                                '&.Mui-selected': { backgroundColor: '#5a1bc3', color: "#fff" },
                                '&:hover': {
                                    backgroundColor: alignment === 'Assigned' ? '#5760c7' : '#8c93e5',
                                    color: "#000"
                                }
                            }} >
                            Assigned
                        </ToggleButton>
                        <ToggleButton
                            value="Unassigned"
                            sx={{
                                borderRadius: "0px 10px 10px 0px", maxHeight: "45px", mt: 1,
                                backgroundColor: alignment === 'Unassigned' ? '#5a1bc3' : 'transparent',
                                '&.Mui-selected': { backgroundColor: '#5a1bc3', color: "#fff" },
                                '&:hover': {
                                    backgroundColor: alignment === 'Unassigned' ? '#5760c7' : '#8c93e5',
                                    color: "#000"
                                }
                            }}>
                            Unassigned
                        </ToggleButton>
                    </ToggleButtonGroup>
                    : <Button
                        sx={{
                            position: 'relative',
                            top: 5,
                            maxHeight: "45px",
                            maxWidth: '150px',
                            marginLeft: 'auto',
                        }}
                        onClick={() => navigate('/newproject')}
                    >
                        Add Project
                    </Button>}
            </Box>

            <CardContent>
                <Grid container spacing={7} sx={{ p: 5 }}>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '250px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        filteredProjects.length > 0 ? (
                            filteredProjects.map((project, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card
                                        sx={{
                                            width: '100%',
                                            height: '250px',
                                            display: 'flex',
                                            backgroundColor: "#f6f7fb",
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                                            boxShadow: hoveredIndex === index
                                                ? "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
                                                : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',

                                            '&:hover': {
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                                                backgroundColor: "#fff",
                                                transform: 'translateY(-5px)',
                                            }
                                        }}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >

                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                <Box
                                                    sx={{
                                                        width: 60,
                                                        height: 60,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: '4px',
                                                        backgroundColor: reactcolors[index % reactcolors.length],
                                                        color: 'white',
                                                        fontSize: '30px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {project.name.charAt(0).toUpperCase()}
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {project.name}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                                        {groupAvatars.map((avatar, idx) => (
                                                            <Avatar
                                                                key={idx}
                                                                sx={{
                                                                    width: 30,
                                                                    height: 30,
                                                                    marginLeft: '-10px',
                                                                    border: '2px solid white',
                                                                }}
                                                                alt={`Avatar ${idx + 1}`}
                                                                src={avatar}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography
                                                variant="body2"
                                                mt={2}
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {project.description}
                                            </Typography>
                                        </CardContent>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                borderTop: '2px dashed #c0c1c4',
                                                padding: "10px 30px 10px 30px"
                                            }}
                                        >
                                            {project.can_access === true ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                    fullWidth
                                                    onClick={() => { navigate(`/managerview?id=${project.id}`) }}
                                                >
                                                    View <IconEye />
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                    fullWidth
                                                    onClick={() => { alert("You are not allowed to perform this action") }}
                                                    disabled={true}
                                                >
                                                    View <IconEye />
                                                </Button>
                                            )}
                                        </Box>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                            >
                                No projects available
                            </Typography>
                        )
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MyProjects;
