import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import SignUp from './Components/Logins/Signup';
import SignIn from './Components/Logins/Signin';
import ForgotPassword from './Components/Logins/ForgotPassword';
import Forgotpasswordsuccess from './Components/Logins/Forgotpasswordsuccess';
import NewProject from './Components/MainPages/NewProject';
import '../src/App.css';
import Designation from './Components/Pages/Designation.js';
import Stakeholder from './Components/Pages/Stakeholder.js';
import Requests from './Components/Pages/Requests.js';
import Managerinput from './Components/Pages/Managerinput.js';
import ManagerView from './Components/Pages/Managerview.js';
import Masterscopeview from './Components/Pages/Masterscopeview.js';
import PrivateRoute from './Components/Logins/PrivateRoute.js';
import MyProjects from './Components/MainPages/Dashboard.js';
import GeneratedFiles from './Components/Pages/Generatedfiles.js';
import Layout from './Components/MainPages/Layout.js';
import ViewGeneratedDoc from './Components/Pages/ViewGeneratedDoc.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7a5af9',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '9px 24px',
          fontSize: '1rem',
          backgroundColor: "#5a1bc2",
          color: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            height: "3rem",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          position: "absolute",
          top: 60,
          margin: 0,
        },
      },
      defaultProps: {
        disableBackdropClick: true,
      },
    },
  },
});



function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/passwordsuccess" element={<Forgotpasswordsuccess />} />
          <Route path="/Dashboard" element={<PrivateRoute><Layout><MyProjects /></Layout></PrivateRoute>} />
          <Route path="/newproject" element={<PrivateRoute><Layout><NewProject /></Layout></PrivateRoute>} />
          <Route path="/managerinput" element={<PrivateRoute><Layout><Managerinput /></Layout></PrivateRoute>} />
          <Route path="/desination" element={<PrivateRoute><Layout><Designation /></Layout></PrivateRoute>} />
          <Route path="/stakeholders" element={<PrivateRoute><Layout><Stakeholder /></Layout></PrivateRoute>} />
          <Route path="/requests" element={<PrivateRoute><Layout><Requests /></Layout></PrivateRoute>} />
          <Route path="/managerview" element={<PrivateRoute><Layout><ManagerView/></Layout></PrivateRoute>} />
          <Route path="/masterScopeview" element={<PrivateRoute><Layout><Masterscopeview/></Layout></PrivateRoute>} />
          <Route path="/generatedfiles" element={<PrivateRoute><Layout><GeneratedFiles/></Layout></PrivateRoute>} />
          <Route path="/generateddocs" element={<PrivateRoute><Layout><ViewGeneratedDoc/></Layout></PrivateRoute>} />

        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
