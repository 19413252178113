import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Grid, InputAdornment, IconButton, Alert } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { IconArrowRight, IconArrowRightToArc, IconEye, IconEyeOff, IconLock, IconMail, IconPhone, IconUser, IconUserPlus } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import SignUpImage from '../../Images/signupimage2.png';
import axios from 'axios';

const SignUp = () => {
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, getValues, trigger } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false)
  const [EmailError, setEmailError] = useState('')
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleconfirmPasswordVisibility = () => {
    setshowconfirmPassword((prev) => !prev);
  };
  const onSubmit = async (data) => {
    try {
      let config = axios.defaults.headers.common = { 'Authorization': `` }
      let req = {
        fullname: data.fullName,
        mobile: data.phone,
        email: data.email,
        password: data.password,
        confirm_password: data.confirmPassword
      };
      const response = await axios.post('apiV1/register/', req, config);
      console.log(response);
      navigate('/')
    } catch (error) {
      console.error('Error generating test:', error.response ? error.response.data : error.message);
      setEmailError(error.response.data.email[0])

    }
  };
  return (
    <Container maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(166deg, #082A68 0%, #591CC2 100%)',
              display: 'flex',
              flexDirection: 'column',
              p: 13,
              width: '100%',
              borderRadius: 2,
              color: "#fff"
            }}
          >
            <Box
              component="img"
              src={SignUpImage}
              alt="Sign In"
              sx={{ maxWidth: '70%', height: 'auto', mb: 4 }}
            />
            <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
              Hey There, Good To See You!
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              We're So Glad To Have You Here Again. Sign In To Reconnect And Continue Enjoying Our Services.
            </Typography>
            <Box sx={{ alignItems: "center" }}>

              <Button component={RouterLink} to="/" variant="contained" size="small" sx={{ maxWidth: "150px", alignItems: "center", maxHeight: "40px", gap: 1, bgcolor: "#7a5af9" }}>
                Sign In <IconArrowRightToArc />
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          bgcolor={'#fff'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 4,
          }}
        >
          <Box sx={{ maxWidth: 550, mx: 'auto', width: '100%' }}>
            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
              Sign Up For A Better Experience!
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: 'center' }}>
              Access Exclusive Tool And Features Designed Just For You. Create Your Account Now.
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                maxWidth: '480px',
                alignItems: 'center',
                mx: 'auto',
                my: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                fullWidth
                placeholder="Full Name"
                variant="outlined"
                margin="normal"
                {...register('fullName', { required: 'Full Name is required' })}
                error={!!errors.fullName}
                helperText={errors.fullName?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconUser size={22} />
                    </InputAdornment>
                  ),
                }}

              />
              <TextField
                fullWidth
                placeholder="Email"
                type="email"
                margin="normal"
                {...register("email", {
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}\.+[A-Z]{2,5}$/i,
                    message: "Please enter a valid email address.",
                  },
                })}
                onKeyUp={() => trigger('email')}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconMail size={22} />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                placeholder="Phone"
                variant="outlined"
                margin="normal"
                {...register("phone", {
                  required: "This field is required",
                  pattern: {
                    value: /^[1-9]{1}[0-9]{9,11}$/,
                    message:
                      "Please enter a valid mobile number",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter min 10 numbers ",
                  },
                  maxLength: {
                    value: 12,
                    message: "Please enter max 12 numbers ",
                  },
                })}
                onKeyUp={() => trigger('phone')}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconPhone size={22} />
                    </InputAdornment>
                  ),
                }}

              />
              <TextField
                fullWidth
                placeholder="Password"
                variant="outlined"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                {...register("password", {
                  required: "This field is required",
                  pattern: {
                    value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                    message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                  },
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock size={22} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyUp={() => {
                  trigger("password");
                }}
                inputProps={{
                  autoComplete: 'password',
                }}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
              <TextField
                fullWidth
                placeholder="Confirm Password"
                variant="outlined"
                type={showconfirmPassword ? 'text' : 'password'}
                margin="normal"
                {...register('confirmPassword', {
                  required: 'Confirm Password is required',
                  validate: (value) =>
                    value === getValues('password') || 'Passwords do not match',
                })}
                onKeyUp={() => trigger("confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock size={22} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleconfirmPasswordVisibility} edge="end">
                        {showconfirmPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
              {EmailError && (
                <Alert severity="error">{EmailError}</Alert>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, textAlign: 'center', borderRadius: "15px", gap: 1, }}
              >
                Sign Up <IconUserPlus size={20} />
              </Button>
              {/* <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
                <Box>Already have an account?</Box>
                <Box
                  onClick={() => navigate('/')}
                  sx={{
                    cursor: 'pointer',
                    ml: 1,
                    color: 'primary.main',
                    textDecoration: 'underline',
                  }}
                >
                  Back to Sign In
                </Box>
              </Box> */}


            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
