import { Button, Typography, Box, Container, Grid } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SignUpImage from '../../Images/success1.png';

const Forgotpasswordsuccess = () => {
    return (
        <Container maxWidth={false} sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container>
                {/* Left Grid */}
                <Grid item  xs={12}md={5}sx={{bgcolor: 'background.paper',display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center',textAlign: 'center',p: { xs: 4, md: 6 }}}>
                    <Box
                        sx={{
                            background: 'linear-gradient(166deg, #082A68 0%, #591CC2 100%)',
                            display: 'flex',
                            flexDirection: 'column',
                            p: { xs: 4, md: 20 },
                            width: '100%',
                            borderRadius: 2,
                            color: "#fff"
                        }}
                    >
                        <Box
                            component="img"
                            src={SignUpImage}
                            alt="Sign Up Success"
                            sx={{ maxWidth: '100%', height: 'auto', mb: 3, ml: 2 }}
                        />
                        <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
                            Hooray!
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4 }}>
                            Your account is back in action!
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Grid */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        bgcolor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        p: { xs: 4, md: 6 },
                    }}
                >
                    <Box sx={{ maxWidth: 550, mx: 'auto', textAlign: 'center' }}>
                        <Typography variant="h4" component="h1" fontWeight="bold" gutterBottom>
                            Password Reset
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                            Your password has been successfully reset. Click below to continue your access.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                component={RouterLink}
                                to="/"
                                variant="contained"
                                size="large"
                                sx={{ px: 4, py: 1, gap: 1 }}
                            >
                                Continue <IconArrowRight />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Forgotpasswordsuccess;
