import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Box, Alert, Grid, InputAdornment, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import SignUpImage from '../../Images/passwordimg2.png'
import { useNavigate } from 'react-router-dom';
import { IconArrowRight, IconLock, IconMail, IconPhotoScan } from '@tabler/icons-react';
import axios from 'axios';

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [secondScree, setSecondscreen] = useState(false)
    const [token, settoken] = useState('')
    const [userid, setuserid] = useState('')
    const [showpasswordChange, setshowpasswordChange] = useState(false)
    const { register, handleSubmit, formState: { errors }, getValues, trigger } = useForm();
    const { register: register1, handleSubmit: handlesubmit1, formState: { errors1 }, getValues: getValues1, trigger: trigger1 } = useForm();
    const { register: passwordregister, handleSubmit: handlesubmitpassword, formState: { passworderrors }, getValues: getValuepassword, trigger: triggeepassword } = useForm();
    const [loading, setloading] = useState(false)
    const [loading2, setloading2] = useState(false)
    const [loading3, setloading3] = useState(false)
    const [otperror, setotperror] = useState('')
    const onSubmit = async (data) => {
        setloading(true)
        try {
            let req = {
                email: data.email,
            };
            let config = axios.defaults.headers.common = { 'Authorization': `` }
            const response = await axios.post('apiV1/forgot_passwordV2/', req, config);
            if (response.status == 200) {
                setSecondscreen(true)
                settoken(response.data.token)
                setloading(false)
            }
            console.log(response);
        } catch (error) {
            console.error('Error generating test:', error.response ? error.response.data : error.message);
            setloading(false)
        }

    };
    const onotpSubmit = async (data) => {
        setloading2(true)
        try {
            let req = {
                otp: data.otp,
                token: token
            };
            let config = axios.defaults.headers.common = { 'Authorization': `` }
            const response = await axios.post('apiV1/check_otp/', req, config);
            if (response.status == 200) {
                setshowpasswordChange(true)
                setSecondscreen(false)
                setuserid(response.data.user_id)
                setloading2(false)
            }
            console.log(response);
        } catch (error) {
            console.error(error)
            setloading2(false)
            setotperror(error.response.data.message)
        }

    };
    const handlepasswordChange = async (data) => {
        setloading3(true)
        try {
            console.log(data);

            let req = {
                password: data.password,
                password2: data.confirmPassword
            };
            let config = axios.defaults.headers.common = { 'Authorization': `` }
            const response = await axios.patch(`apiV1/change-passwordv2/${userid}/`, req, config);
            if (response.status == 200) {
                setshowpasswordChange(true)
                navigate('/passwordsuccess')
                setloading3(false)
            }
            console.log(response);
        } catch (error) {
            console.error('Error generating test:', error.response ? error.response.data : error.message);
            setloading3(false)

        }

    };

    return (
        <Container maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
            <Grid container>
                <Grid item xs={12} md={5} sx={{ bgcolor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', p: 2 }}>
                    <Box
                        sx={{
                            background: 'linear-gradient(166deg, #082A68 0%, #591CC2 100%)',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 20,
                            width: '100%',
                            borderRadius: 2,
                            color: "#fff"
                        }}
                    >
                        <Box
                            component="img"
                            src={SignUpImage}
                            alt="Sign In"
                            sx={{ maxWidth: '90%', height: 'auto', mb: 4, ml: 3 }}
                        />
                        <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
                            Memory Playing Tricks?
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4 }}>
                            Let's Help You Out!
                        </Typography>
                    </Box>
                </Grid>

                {showpasswordChange ? (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        bgcolor={'#fff'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            p: 4,
                        }}
                    >
                        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" sx={{ textAlign: "center" }}>
                            Set Your New Password
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: 'center' }}>
                            Your security, Your rules
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handlesubmitpassword(handlepasswordChange)}
                            sx={{
                                width: '480px',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                margin: "0px auto"
                            }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Password"
                                variant="outlined"
                                margin="normal"
                                disabled={loading3}
                                type="password"
                                {...passwordregister("password", {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                                        message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                                    },
                                })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconLock size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyUp={() => {
                                    triggeepassword("password");
                                }}
                                inputProps={{
                                    autoComplete: 'password',
                                }}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                            <TextField
                                fullWidth
                                placeholder="Confirm Password"
                                variant="outlined"
                                margin="normal"
                                type="password"
                                disabled={loading3}
                                {...passwordregister('confirmPassword', {
                                    required: 'Confirm Password is required',
                                    validate: (value) =>
                                        value === getValuepassword('password') || 'Passwords do not match',
                                })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconLock size={22} />
                                        </InputAdornment>
                                    ),
                                }}
                                onCopy={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, textAlign: 'center' }}
                                disabled={loading3}
                            >
                                {loading3 ? (
                                    <CircularProgress
                                        style={{
                                            display: "inline-block",
                                            textAlign: "center",
                                            height: "30px",
                                            width: "30px",
                                        }}
                                    />
                                ) : (
                                    <>
                                        Confirm <IconArrowRight />
                                    </>
                                )}
                            </Button>
                        </Box>
                    </Grid>
                ) : secondScree === false ? (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        bgcolor={'#fff'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            p: 4,
                        }}
                    >
                        <Box sx={{ maxWidth: 550, mx: 'auto', width: '100%' }}>
                            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" sx={{ textAlign: "center" }}>
                                Forgot Your Password?
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: 'center' }}>
                                Enter your registered email to reset it
                            </Typography>

                            <Box
                                component="form"
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    maxWidth: '480px',
                                    alignItems: 'center',
                                    mx: 'auto',
                                    my: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    placeholder="Enter Your Email"
                                    variant="outlined"
                                    margin="normal"
                                    disabled={loading}
                                    {...register('email', { required: 'Email is required' })}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconMail size={22} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 3, textAlign: 'center' }}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            style={{
                                                display: "inline-block",
                                                textAlign: "center",
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    ) : (
                                        <>
                                            Confirm <IconArrowRight />
                                        </>
                                    )}

                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                ) : (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        bgcolor={'#fff'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            p: 4,
                        }}
                    >
                        <Box sx={{ maxWidth: 550, mx: 'auto', width: '100%' }}>
                            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" sx={{ textAlign: "center" }}>
                                Enter Otp
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: 'center' }}>Enter the 6-digit code sent to your registered email</Typography>
                            <Box
                                component="form"
                                onSubmit={handlesubmit1(onotpSubmit)}
                                sx={{
                                    maxWidth: '480px',
                                    alignItems: 'center',
                                    mx: 'auto',
                                    my: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    placeholder="Enter Your OTP"
                                    variant="outlined"
                                    id='otp'
                                    margin="normal"
                                    disabled={loading2}
                                    {...register1('otp', { required: 'OTP is required' })}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconPhotoScan size={22} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        autocomplete: 'off',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                />
                                {otperror && <Alert severity="error">{otperror ? otperror : ""}</Alert>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: 3, textAlign: 'center' }}
                                    disabled={loading2}
                                >
                                    {loading2 ? (
                                        <CircularProgress
                                            style={{
                                                display: "inline-block",
                                                textAlign: "center",
                                                height: "30px",
                                                width: "30px",
                                            }}
                                        />
                                    ) : (
                                        <>
                                            Confirm <IconArrowRight />
                                        </>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default ForgotPassword;