import React from 'react';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import Sidebar from './Sidebar';
import Breadcrumb from './Breadcrumb';

const Layout = ({ children }) => {
  return (
    <Box display="flex" height="100vh">
      {/* Sidebar: Fixed to the left side */}
      <Sidebar
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          width: "250px",  
          backgroundColor: "#F4F4F4", 
          zIndex: 10, 
          overflowY: "auto", 
        }}
      />
      
      {/* Main content area */}
      <Box
        sx={{
          flex: 1,
          minHeight: "100vh",
          backgroundColor: "#F9FAFB",
          display: "flex",
          flexDirection: "column",
         
        }}
      >
        {/* Breadcrumb: Fixed to the top of the page */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 5, 
            padding: "7px 21px 0px 28px",
            margin:"10px 0px 10px 0px"
          }}
        >
          <Breadcrumb />
        </Box>
        <Box
          sx={{
            flex: 1,
            overflowY: "auto", 
            padding: "20px", 
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
