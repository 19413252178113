import { Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import axios from 'axios';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

const Designation = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [isSubmitting, setisSubmitting] = useState(false)
    const [open, setOpen] = useState(false)
    const [data, setdata] = useState(false)
    const [editid, seteditid] = useState('')
    const [deleteuserid, setdeleteuserid] = useState('')
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [Addedsuccess, setAddedsuccess] = useState(false);
    const [AddedMessage, setAddedMessage] = useState('');
    const [message, setMessage] = useState("");
    const [BackedMessage, setBackedMessage] = useState("");
    const [MessageType, setMessageType] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const columns = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
            selector: (row) => row.meta, cell: row => row.name
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>TeamName</Typography>,
            selector: (row) => row.meta, cell: row => row.team_name
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Actions</Typography>,
            selector: (row) => row.meta,
            cell: row => (
                <>
                    <IconButton onClick={() => { onloadeditData(row); seteditid(row.id) }}>
                        <IconEdit color={"#182286"} />
                    </IconButton>
                    <IconButton onClick={() => { setdeleteuserid(row.id); setOpenDeleteDialog(true) }}>
                        <IconTrash color={"red"} />
                    </IconButton>
                </>
            ),
        }

    ];
    const getUser = async (page = currentPage, rowsperpage = perPage) => {
        try {
            setisSubmitting(true)
            const response = await axios.get(`apiV1/designations/?limit=${rowsperpage}&offset=${(page - 1) * rowsperpage}`);
            if (response.status === 200) {
                setdata(response.data)
                setisSubmitting(false)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    const deleteUser = async () => {
        try {
            const response = await axios.delete(`apiV1/designations/${deleteuserid}/`);
            console.log(response.data);
            setMessage(response.data.message)
            if (response.status === 200) {
                setMessageType("Success")
                setOpenDeleteDialog(false)
                setOpenMessageDialog(true)
                setisSubmitting(false)
                getUser()
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setMessageType("Error")
            setisSubmitting(false)
            setOpenMessageDialog(true)
            setOpenDeleteDialog(false)
            setMessage(error.response.data.message)
        }
    }
    const onSubmit = async (data) => {
        if (editid) {
            setisSubmitting(true)
            try {
                const req = {
                    name: data.Designation,
                    team_name: data.Teamname,
                };
                const response = await axios.put(`apiV1/designations/${editid}/`, req);
                console.log(response, 'responseresponseupdate');

                if (response.status === 200) {
                    setOpen(false)
                    setBackedMessage(response.data.message)
                    setAddedsuccess(true)
                    setisSubmitting(false)
                    setAddedMessage("Success")
                    getUser()
                }
            } catch (error) {
                console.error(error, 'errorerror');
                setisSubmitting(false)
                setOpen(false)
                setAddedMessage("Error")
            }
        }
        else {
            setisSubmitting(true)
            try {
                const req = {
                    name: data.Designation,
                    team_name: data.Teamname,
                };
                const response = await axios.post("apiV1/designations/", req);
                console.log(response.data.message, 'responseresponseadde');
                if (response.status === 200) {
                    setOpen(false)
                    setBackedMessage(response.data.message)
                    setAddedMessage("Success")
                    setAddedsuccess(true)
                    setisSubmitting(false)
                    getUser()
                }
            } catch (error) {
                console.error("Error generating project:", error.response ? error.response.data : error.message);
                setisSubmitting(false)
                setBackedMessage(error.response.data.message[0])
                setAddedMessage("Error")
                setAddedsuccess(true)
                setOpen(false)
            }
        }
    };
    useEffect(() => {
        getUser()
    }, [])
    const onloadeditData = (data) => {
        setOpen(true)
        setValue('Designation', data.name)
        setValue('Teamname', data.team_name)
    }
    
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handlePageChange = page => {
        getUser(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        getUser(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    return (
        <>
            <Card sx={{ display: 'flex', flexDirection: 'column', p: 5, margin: "0px 0px 0px 9px", position: 'relative' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                    <Box sx={{ position: 'absolute', top: 6, right: 10 }}>
                        <Button onClick={() => { setOpen(true); reset(); seteditid('') }} variant="contained">Add Designation</Button>
                    </Box>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        fixedHeaderScrollHeight='600px'
                        fixedHeader
                        persistTableHead
                        paginationTotalRows={totalRows}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={perPage}
                        paginationDefaultPage={currentPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </CardContent>
            </Card>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth disableEscapeKeyDown >
                <DialogTitle>{editid ? "Update" : "Add"} Designation</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ mb: 1 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >Designation<span style={{ color: "red", fontWeight: 800 }}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Designation Name"
                                {...register("Designation", { required: "Designation Name is required" })}
                                error={!!errors.Designation}
                                helperText={errors.Designation?.message}
                            />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 500, mb: 1, display: "block" }}
                            >
                                Team Name <span style={{ color: "red", fontWeight: 800 }}>*</span>
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Team Name"
                                {...register("Teamname", { required: "Team Name  is required" })}
                                error={!!errors.Teamname}
                                helperText={errors.Teamname?.message}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                            <Button variant="contained" color="warning" onClick={() => { setOpen(false) }} sx={{ marginRight: '10px' }}>  Cancel </Button>
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>{isSubmitting ? <CircularProgress disabled style={{ height: '30px', width: '30px', textAlign: "center" }} /> : "Submit"}</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={Addedsuccess} onClose={() => setAddedsuccess(false)} maxWidth="sm" fullWidth disableEscapeKeyDown >
                <DialogTitle>{AddedMessage}</DialogTitle>
                <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Box className="text-danger font_54">
                        {AddedMessage === "Success" ? (<CheckCircle fontSize="large" color="success" />) : (<ErrorOutline fontSize="large" color="error" />)}
                    </Box>
                    <Typography variant="body1">{BackedMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddedsuccess(false)} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="delete-dialog-title" maxWidth="sm" fullWidth>
                <DialogTitle id="delete-dialog-title">Delete Confirmation</DialogTitle>
                <Divider />
                <DialogContent sx={{ textAlign: 'center' }}>
                    <ErrorOutline fontSize="large" color="warning" />
                    <Typography variant="body1" sx={{ textAlign: "center" }}>Are you sure you want to delete this?</Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} sx={{ backgroundColor: "#fe0000" }}>
                        Cancel
                    </Button>
                    <Button onClick={deleteUser} sx={{ backgroundColor: "#6dc500" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openMessageDialog}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="success-dialog-title" className='bg-my-primary p-3 font_weight_700'>
                    {MessageType}
                </DialogTitle>
                <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Box className="text-danger font_54">
                        {MessageType === "Success" ? (<CheckCircle fontSize="large" color="success" />) : (<ErrorOutline fontSize="large" color="error" />)}
                    </Box>
                    <Typography variant="h6" className="red_dark_text font_weight_700 mt-2">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="center" width="100%" mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpenMessageDialog(false);
                            }}
                        >
                            Okay
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Designation
