import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Container, Checkbox, FormControlLabel, Link, Grid, InputAdornment, Alert, IconButton, CircularProgress } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { IconArrowLeftToArc, IconArrowRight, IconArrowRightFromArc, IconArrowRightToArc, IconEye, IconEyeOff, IconLock, IconMail, IconUserPlus } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import SignInImage from '../../Images/smilingman1.png';
import axios from 'axios';
const SignIn = () => {
  const navigate = useNavigate()
  const [Apierror, setApiError] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, trigger } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let req = {
        email: data.email,
        password: data.password,
      };
      if (data.rememberMe) {
        sessionStorage.setItem("username", data.email)
        sessionStorage.setItem("Password", data.password)
      } else {
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("Password")
      }
      const response = await axios.post('/apiV1/login/', req);
      if (response.status === 200) {
        sessionStorage.setItem("AdminToken", response.data.access)
        await userInfo()
          setLoading(false);
          navigate('/Dashboard');
      }
    } catch (error) {
      setApiError(error?.response.data.detail);
      console.error('Error generating test:', error.response ? error.response.data : error.message);
      setLoading(false);
    }

  };
  const userInfo = async () => {
    try {
      const config = axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("AdminToken")}` }
      const response = await axios.get('/apiV1/user-info/', config);
      console.log(response.data);
      if (response.status == 200) {
        sessionStorage.setItem("user-info", JSON.stringify(response.data))
      }
    } catch (error) {
      console.error('Error generating test:', error.response ? error.response.data : error.message);
    }
  }
  useEffect(() => {
    const savedEmail = sessionStorage.getItem('username');
    const savedPassword = sessionStorage.getItem('Password');
    if (savedEmail && savedPassword) {
      setValue('email', savedEmail);
      setValue('password', savedPassword);
    }
  }, []);
  return (
    <Container maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
      <Grid container>
        {/* Left Section */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            p: 2
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(166deg, #082A68 0%, #591CC2 100%)',
              display: 'flex',
              flexDirection: 'column',
              p: 22,
              width: '100%',
              borderRadius: 2,
              color: "#fff"
            }}
          >
            <Box
              component="img"
              src={SignInImage}
              alt="Sign In"
              sx={{ maxWidth: '90%', height: 'auto', mb: 4 }}
            />
            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
              Take The First Step!
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Your Journey Starts Here. Create Your Account To Explore New Possibilities.
            </Typography>
            <Box sx={{ alignItems: "center" }}>

              <Button
                component={RouterLink}
                to="/signup"
                variant="contained"
                size="small"
                sx={{ gap: 1, bgcolor: "#7a5af9" }}
              >
                Sign Up <IconUserPlus />
              </Button>
            </Box>

          </Box>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 4,
            bgcolor: "#fff",
          }}
        >
          <Box sx={{ maxWidth: 400, mx: 'auto', width: '100%', }}>
            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
              Sign In To BA-Tool
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
              Enter Your Personal Details And Start Working With Us
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {/* Email Field */}
              <TextField
                fullWidth
                placeholder="Email"
                variant="outlined"
                margin="normal"
                type="email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Enter a valid email',
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconMail size={22} />
                    </InputAdornment>
                  ),
                }}
                onKeyUp={() => { trigger('email'); setApiError('') }}
              />

              {/* Password Field */}
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}$/,
                    message: "Password must be 8-16 characters, with at least one digit, one lowercase, and one uppercase letter.",
                  },
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock size={22} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <IconEye size={22} /> : <IconEyeOff size={22} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyUp={() => { trigger('password'); setApiError('') }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
                <FormControlLabel
                  control={<Checkbox {...register('rememberMe')} />}
                  label="Remember Me"
                />
                <Typography onClick={() => navigate('/forgot-password')} sx={{ cursor: 'pointer', ml: 1, color: 'primary.main', textDecoration: 'underline', textAlign: "center" }}>
                  Forgot Your Password?
                </Typography>
              </Box>
              {Apierror && <Alert severity="error">{Apierror}</Alert>}
              {/* Submit Button */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="small"
                sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                disabled={loading} // Disable button during loading
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: '#fff' }} />
                ) : (
                  <>
                    Sign In <IconArrowRightToArc />
                  </>
                )}
              </Button>

              {/* <Box sx={{ display: "flex", mt: 2, alignItems: "center", textAlign: 'center', marginLeft: "5rem" }}>
                <Typography>Don't have an account?</Typography>
                <Typography onClick={() => navigate('/signup')} sx={{ cursor: 'pointer', ml: 1, color: 'primary.main', textDecoration: 'underline', textAlign: "center" }}>
                  Sign Up
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignIn;
