import { Button, CircularProgress, FormControl, FormHelperText, Select, TextField, Typography, MenuItem } from '@mui/material'
import { Box, Grid } from '@mui/system';
import { IconBookmark, IconBookmarkEdit, IconWand } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { parse } from 'node-html-parser';
const Masterscopeview = () => {
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    const isView = params.get("is_view");
    const namefromurl = params.get("name");
    const [inputText, setinputText] = useState([])
    const [data, setdata] = useState([])
    const [inputTextid, setinputTextid] = useState([])
    const [projectData, setprojectData] = useState('')
    const [loader, setLoader] = useState(false)
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm()
    const [contentEdittxt, setcontentEdittxt] = useState('')
    const [selectedId, setSelectedId] = useState(namefromurl);
    const isUpdateDisabled = Object.entries(inputText).some(
        ([key, value]) => key !== "master_input" && value !== false
        
    );
    
    const getUserData = async () => {
        try {
            const response = await axios.get(`/apiV1/projects/${id}/`);
            console.log(response.data, 'getUserDatagetUserDatagetUserData');
            if (response.status == 200) {
                setdata(response.data)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const getProjectdata = async () => {
        setLoader(true)
        try {
            if (isView === 'true') {
                console.log("Skipping API call because 'is_view' is true in the URL.");
                return;
            }
            let Data = {
                master_input_name: namefromurl
            }

            const response = await axios.patch(`apiV1/project/${id}/generate-master-input/`, Data);
            console.log(response.data);

            if (response.status === 200) {
                getProjectdataView()
                getProjectupdate()
                setLoader(false)
                setprojectData(response.data);
                const rawHtml = response.data?.data || '';
                const parsedHtml = parse(rawHtml);
                let bodyContent = parsedHtml.querySelector('body')?.innerHTML || '';
                bodyContent = bodyContent.replace(/\s+/g, ' ').trim();
                setinputText(bodyContent);
                setcontentEdittxt(bodyContent)

            }
        } catch (error) {
            console.error('Error fetching project data:', error);
            setLoader(false)
        }
    };
    useEffect(() => {
        if (id) {
            getProjectdata()
            getUserData()
        }
    }, [id])
    const getProjectdataView = async () => {
        try {
            // if (isView === 'false') {
            //     console.log("Skipping API call because 'is_view' is true in the URL.");
            //     return;
            // }
            setLoader(true)
            const response = await axios.get(`apiV1/project/${id}/generate-master-input/`);
            console.log(response.data);
            if (response.status === 200) {
                setLoader(false)
                setinputTextid(Object.keys(response.data.data))
                const firstKey = Object.keys(response.data.data)[0];
                setSelectedId(firstKey)
                getProjectupdate(firstKey)

                const rawHtml = response.data.data;
                // setinputTextid(rawHtml);
                console.log(rawHtml, 'rawhtmlfromview');

            }
        } catch (error) {
            console.error('Error fetching project data:', error);
            setLoader(false)
        }
    };
    useEffect(() => {
        if (isView === "true") {
            getProjectdataView()
        }
    }, [isView])
    const getProjectupdate = async (selectedName = selectedId) => {
        try {
            setLoader(true)
            const response = await axios.get(`/apiV1/project/${id}/generate-master-input/?master_input_name=${selectedName}`);
            console.log(response.data.data, 'response');

            if (response.status === 200) {
                setLoader(false)
                setprojectData(response.data.data);
                const rawHtml = response.data.data;
                setinputText(rawHtml.master_input);
                setcontentEdittxt(rawHtml.master_input)
            }
        } catch (error) {
            console.error('Error fetching project data:', error);
            setLoader(false)
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('project', id);
        formData.append('inputed_text', data.inputText);
        try {
            const response = await axios.post("/apiV1/stakeholder-inputs/", formData);
            if (response.status === 201) {
                navigate('/managerview')
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);

        }
    };
    const hanldeAisuggestion = async () => {
        try {
            setLoader(true)
            let Data = {
                text: inputText
            }
            const response = await axios.post(`/apiV1/aisuggestion/`, Data);
            console.log(response, 'response');

            if (response.status == 200) {
                setinputText(response.data.result)
                setcontentEdittxt(response.data.result)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoader(false)
        }
    }
    const SaveInputText = async () => {
        try {
            setLoader(true)
            let Data = {
                master_input: contentEdittxt
            }
            const response = await axios.post(`/apiV1/update_master_input/${id}/`, Data);
            console.log(response.data, 'responseasads')
            if (response.status == 200) {
                setinputText(response.data.data.master_input)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoader(false)
        }
    }
    const UpdateInputText = async () => {
        try {
            setLoader(true)
            let Data = {
                master_html: contentEdittxt,
                master_input_name:selectedId
            }
            const response = await axios.patch(`/apiV1/update-masterdata/${id}/`, Data);
            console.log(response.data, 'responseasads')
            if (response.status == 200) {
                setinputText(response.data.data.master_input)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoader(false)
        }
    }
    // useEffect(() => {
    //     if (selectedId) {
    //         getProjectupdate()
    //     }
    // }, [selectedId])
    const handleIdChange = (event) => {
        const selectedId = event.target.value;
        console.log(selectedId, 'asjdkhdkjahsjkdhasjkdhjkdhjksdhkash');
        setSelectedId(selectedId);
        getProjectupdate(selectedId)
        const selectedData = inputTextid.find((item) => item.id === selectedId);
        setinputText(selectedData?.master_input || "");
    };
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ padding: "30px", backgroundColor: "#fff", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", margin: "0px 0px 0px 9px" }}  >
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "80%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ mb: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                                Designation
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter Designation"
                                value={data?.is_manager ? "Manager" : ''}
                                disabled
                                {...register('designation')}
                                error={!!errors.designation}
                                helperText={errors.designation?.message}
                            />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                                Email
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="manager@company.com"
                                value={data?.manager_email}
                                disabled
                                {...register('email')}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                                Select ID
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={selectedId}
                                    onChange={handleIdChange}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Select ID" }}
                                    sx={{
                                        borderRadius: '10px',
                                        maxHeight: "2.8rem",
                                    }}
                                    disabled={loader}
                                >
                                    {Array.isArray(inputTextid) && inputTextid.length > 0 ? (
                                        inputTextid.map((name, index) => (
                                            <MenuItem key={index} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <p>No data available</p>
                                    )}



                                </Select>
                                <FormHelperText>Select an ID to view the Master Input</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#8069e5" }}>
                                Master Input : Scope Overview
                            </Typography>
                            {/* <Button
                                onClick={hanldeAisuggestion}
                                disabled={loader}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'transparent',
                                    color: '#7a5bf6',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#6747cc',
                                    },
                                }}
                            >
                                <IconWand style={{ fontSize: '18px', marginRight: '4px' }} /> AI Suggestion
                            </Button> */}
                        </Box>
                        <div
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: '100%',
                                maxWidth: '1500px',
                            }}
                        >
                            <div
                                contentEditable
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '12px',
                                    padding: '10px',
                                    fontSize: '16px',
                                    minHeight: '150px',
                                    height: '300px',
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    overflowY: 'auto',
                                    opacity: loader ? 0.5 : 1,
                                    pointerEvents: loader ? 'none' : 'auto',
                                }}
                                onInput={(e) => setcontentEdittxt(e.currentTarget.innerHTML)}
                                dangerouslySetInnerHTML={{ __html: inputText }}
                            />

                            {loader && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 10,
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}
                        </div>

                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ gap: 1, bgcolor: "#7a5af9" }}
                            onClick={UpdateInputText}
                            disabled={loader || isUpdateDisabled === false}
                        >
                            Update<IconBookmarkEdit />
                        </Button>
                        {/* <Button
                            variant="contained"
                            size="small"
                            sx={{ gap: 1, bgcolor: "#7a5af9" }}
                            onClick={SaveInputText}
                            disabled={loader}
                        >
                            Save<IconBookmark />
                        </Button> */}
                    </Box>


                    <Box sx={{ mt: 2, }}>
                        <Button variant="contained" fullWidth type="submit" disabled={loader} onClick={() =>{navigate(`/generatedfiles?id=${id}&name=${selectedId}`)}}>
                            Proceed
                        </Button>
                    </Box>
                </form>
            </Grid>
        </Grid>

    )
}

export default Masterscopeview
