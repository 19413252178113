import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const userInfo = JSON.parse(sessionStorage.getItem('user-info'));

  if (!userInfo) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
