import { Alert, Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material'
import { Box, Grid, Stack } from '@mui/system';
import { IconEdit, IconEye, IconBubbleText, IconFileText, IconGitPullRequest, IconX, IconSend2, IconRestore } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const ManagerView = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [data, setData] = useState('')
    const [Loader, setLoader] = useState(false)
    const [StakeholderData, setStakeholderData] = useState([])
    const [CommentDialog, setCommentDialog] = useState(false)
    const [BackendError, setBackendError] = useState('')
    const [CorrectionReq, setCorrectionReq] = useState(false)
    const [correctionText, setCorrectionText] = useState("");
    const [commenttext, setcommenttext] = useState("");
    const [error, setError] = useState(false);
    const [OpenMasterDialog, setOpenMasterDialog] = useState(false)
    const [requestStakeid, setrequestStakeid] = useState('')
    const [commentStakeId, setcommentStakeId] = useState('')
    const [CommentsHistory, setCommentsHistory] = useState([])
    const [RequestHistory, setRequestHistory] = useState([])
    const [RequestData, setRequestData] = useState('')
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    const getUser = async () => {
        try {
            setLoader(true)
            const response = await axios.get(`/apiV1/projects/${id}/`);
            console.log(response.data);
            if (response.status == 200) {
                setLoader(false)
                setData(response.data)
                setStakeholderData(response.data.stakeholders)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setLoader(false)
        }
    };
    useEffect(() => {
        getUser()
    }, [])
    const handleSend = async () => {
        if (correctionText.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            input_id: requestStakeid,
            approval_status: "pending",
            comment: correctionText,
            project_id: id
        };

        try {
            const response = await axios.post("/apiV1/raise-request/", requestData);
            if (response.status === 200) {
                setError(false);
                setCorrectionReq(false)
                setCorrectionText('')
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const handleCommentChange = (event) => {
        setcommenttext(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const handleTextChange = (event) => {
        setCorrectionText(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const getCommets = async (data) => {
        try {
            const response = await axios.get(`/apiV1/comments/comments/?project_id=${id}&commented_on=${data}`);
            console.log(response);

            if (response.status == 200) {
                setCommentsHistory(response.data.comments)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const getRequest = async (data) => {
        try {
            const response = await axios.get(`/apiV1/raise-request/requests-for-project/?project_id=${id}&input_id=${data}`);
            if (response.status == 200) {
                console.log(response, 'response');
                setRequestData(response.data[0])
                setRequestHistory(response.data[0].comments)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const handlePostComment = async () => {
        if (commenttext.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            commented_on: commentStakeId,
            comment: commenttext,
            project: id
        };

        try {
            const response = await axios.post("/apiV1/comments/", requestData);
            console.log(response, 'responseresponse');
            if (response.status === 201) {
                setcommenttext('')
                setCommentDialog(false);
                setError(false);
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const handleApproval = async (data) => {
        const requestData = {
            approval_status: data
        };

        try {
            const response = await axios.put(`/apiV1/raise-request/${RequestData.id}/update-approval/`, requestData);
            console.log(response, 'responseresponse');
            if (response.status === 200) {
                setCorrectionReq(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const onSubmit = async (data) => {
        const requestData = {
            master_input_name: data.name
        };
        setLoader(true)
        try {
            const response = await axios.post(`/apiV1/project/${id}/generate-master-input/`, requestData);
            if (response.status === 201) {
                setOpenMasterDialog(false)
                console.log(response,'responsefrom save');
                reset()
                navigate(`/masterScopeview?id=${id}&name=${data.name}`)
                setLoader(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.message);
            setLoader(false)
        }
    };
    return (
        <>
            <Grid container sx={{ justifyContent: "center" }} style={{ padding: "30px", backgroundColor: "#fff", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", margin: "0px 0px 0px 9px" }}  >
                {data.can_access === true ?
                    <Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ display: "flex", gap: 2, padding: "10px 15px 2px 13px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: "#f2eefd", borderRadius: "10px" }}>
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            Designation:
                                        </Typography>
                                        <TextField
                                            disabled
                                            fullWidth
                                            placeholder="Enter Designation"
                                            value={data.is_manager}
                                            sx={{
                                                height: '2.7rem',
                                                '& .MuiInputBase-root': {
                                                    height: '100%',
                                                    fontSize: '1rem',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                            Email:
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Enter Your email"
                                            disabled
                                            value={data?.manager_email}
                                            sx={{
                                                height: '2.7rem',
                                                '& .MuiInputBase-root': {
                                                    height: '100%',
                                                    fontSize: '1rem',
                                                },
                                            }}
                                        />
                                    </Stack>
                                </Box>
                                {data.is_manager_submitted === true ? <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, maxHeight: '40px', }}
                                    onClick={() => navigate(`/managerinput?stakeholderid=${data.manager_id}&projectid=${data.id}&is_view=true`)}
                                >
                                    View <IconEye />
                                </Button> : null}
                                {data.is_manager_submitted === false ? <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, maxHeight: '40px', }}
                                    onClick={() => navigate(`/managerinput?stakeholderid=${data.manager_id}&projectid=${data.id}`)}
                                >
                                    Edit <IconEdit />
                                </Button> : null}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 2 }}>
                            {StakeholderData.map((item, index) => (
                                <Box sx={{ display: "flex", gap: 2, padding: "10px" }}>
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                                Designation:
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                disabled
                                                placeholder="Enter Designation"
                                                value={item.designation}
                                                sx={{
                                                    height: "2.7rem",
                                                    "& .MuiInputBase-root": {
                                                        height: "100%",
                                                        fontSize: "1rem",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box sx={{ mb: 1 }}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                                Email:
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                placeholder="Enter Email"
                                                disabled
                                                value={item.email}
                                                sx={{
                                                    height: "2.7rem",
                                                    "& .MuiInputBase-root": {
                                                        height: "100%",
                                                        fontSize: "1rem",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box sx={{ display: "flex", gap: 2 }}>
                                        {item.is_submitted === true ? <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => navigate(`/managerinput?stakeholderid=${item.id}&projectid=${data.id}&is_view=true`)}
                                        >
                                            View <IconEye />
                                        </Button> : null}
                                        {item.is_submitted === false ? <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => navigate(`/managerinput?stakeholderid=${item.id}&projectid=${data.id}`)}
                                        >
                                            Edit <IconEdit />
                                        </Button> : null}
                                        {item.is_submitted === false ? <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => { setCommentDialog(true); setcommentStakeId(item.id); getCommets(item.id) }}
                                        >
                                            Comment <IconBubbleText />
                                        </Button> : null}
                                        {item.is_submitted === true && item.can_see_req === true ? <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ display: "flex", alignItems: "center", gap: 1, maxHeight: "40px" }}
                                            onClick={() => { setrequestStakeid(item.id); setCorrectionReq(true); getRequest(item.id) }} >
                                            Request for correction <IconGitPullRequest />
                                        </Button> : null
                                        }
                                    </Box>
                                </Box>))}

                            {/* {showTextArea && (
                            <Box sx={{ mt: 2 }}>
                                <textarea
                                    placeholder="Provide Your Input"
                                    value={correctionText}
                                    onChange={handleTextChange}
                                    rows={10}
                                    style={{
                                        width: '100%',
                                        height: '300px',
                                        borderRadius: '12px',
                                        padding: '10px',
                                        fontSize: '16px',
                                        border: '1px solid #ebe7fe',
                                    }}
                                />
                                {error && (
                                    <Typography variant="body2" sx={{ color: 'red', mt: 1 }}>
                                        Please provide your input before submitting.
                                    </Typography>
                                )}
                                {BackendError && <Alert severity='error'>{BackendError ? BackendError : ''}</Alert>}
                                <Button
                                    variant="contained"
                                    sx={{ mt: 2, bgcolor: "red" }}
                                    onClick={() => { setShowTextArea(false); setError(''); setCorrectionText('') }}
                                >
                                    Cancel <IconX />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, ml: 2 }}
                                    onClick={handleSend}
                                >
                                    Send <IconSend />
                                </Button>

                            </Box>
                        )} */}
                        </Grid>
                        <Grid
                            item xs={12} sm={12} md={12} lg={12}
                            sx={{ pt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2 }}
                        >
                            {data.is_master_input_generated === false ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ gap: 2 }}
                                    onClick={() => { setOpenMasterDialog(true) }}
                                >
                                    <IconFileText /> Generate Master Input
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ gap: 2 }}
                                    onClick={() =>{ setOpenMasterDialog(true)}}
                                >
                                    <IconRestore />  Re-Generate Master Input
                                </Button>
                            )}

                            {data.is_master_input_generated === true ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ gap: 2 }}
                                    onClick={() => navigate(`/masterScopeview?id=${id}&is_view=true`)}
                                >
                                    View <IconEye />
                                </Button>
                            ) : null}
                        </Grid>



                    </Grid> : <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                        {Loader ? (
                            <CircularProgress />
                        ) : (
                            <Box sx={{ marginBottom: 2 }}>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: '6rem',
                                        fontWeight: 'bold',
                                        color: '#ff4d4f',
                                        marginBottom: 1,
                                        textAlign: "center"
                                    }}
                                >
                                    404
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '1.25rem',
                                        color: '#555',
                                        marginBottom: 3,
                                    }}
                                >
                                    Ooops!!! The page you are looking for is not found
                                </Typography>

                                {/* Ensure the button is properly aligned */}
                                <Button
                                    onClick={() => navigate('/Dashboard')}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 2,
                                        padding: '10px 20px',
                                        fontSize: '1rem',
                                        fontWeight: '600',
                                    }}
                                >
                                    Back To Home
                                </Button>
                            </Box>
                        )}
                    </div>}
            </Grid>
            {/* ==================Dilogs===================== */}

            <Dialog open={CorrectionReq} onClose={() => setCorrectionReq(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Request For Correction
                    <IconButton
                        onClick={() => setCorrectionReq(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                        {RequestHistory.map((message) => (
                            <Box
                                key={message.timestamp} // Use timestamp or a unique key
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                gap={2}
                                mb={2} // Add some spacing between messages
                            >
                                {/* Avatar with first letter of texted_by */}
                                <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                    {message.texted_by.charAt(0).toUpperCase()}
                                </Avatar>

                                <Box>
                                    {/* Message Box */}
                                    <Box
                                        sx={{
                                            backgroundColor: "#f0f0f0",
                                            color: "#000",
                                            borderRadius: 2,
                                            padding: "8px 16px",
                                            maxWidth: "100%",
                                        }}
                                    >
                                        <Typography variant="body1">{message.text_message}</Typography>
                                    </Box>

                                    {/* Timestamp */}
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#888",
                                            display: "block",
                                            mt: 0.5,
                                        }}
                                    >
                                        {new Date(message.timestamp).toLocaleString()} {/* Format timestamp */}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={correctionText}
                            onChange={handleTextChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handleSend()}
                        >
                            <IconSend2 />
                        </Button>
                    </div>


                    <Divider />
                    <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#6dc308' }} onClick={() => handleApproval('approved')}>
                            Approve
                        </Button>
                        <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }} onClick={() => handleApproval('rejected')}>
                            Reject
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {/* ================================================================================ */}
            <Dialog open={CommentDialog} onClose={() => setCommentDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Comments
                    <IconButton
                        onClick={() => setCommentDialog(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                        {CommentsHistory.map((message) => (
                            <Box
                                key={message.created_at}
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                gap={2}
                                mb={2}
                            >
                                <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                    {message.commented_by__email.charAt(0).toUpperCase()}
                                </Avatar>

                                <Box>
                                    <Box
                                        sx={{
                                            backgroundColor: "#f0f0f0",
                                            color: "#000",
                                            borderRadius: 2,
                                            padding: "8px 16px",
                                            maxWidth: "100%",
                                        }}
                                    >
                                        <Typography variant="body1">{message.comment}</Typography>
                                    </Box>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: "#888",
                                            display: "block",
                                            mt: 0.5,
                                        }}
                                    >
                                        {new Date(message.created_at).toLocaleString()}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={commenttext}
                            onChange={handleCommentChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handlePostComment()}
                        >
                            <IconSend2 />
                        </Button>
                    </div>


                    {/* <Divider /> */}
                    {/* <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" sx={{ backgroundColor: '#6dc308' }}>
                            Send
                        </Button>
                        <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }}>
                            Reject
                        </Button>
                    </Box> */}
                </DialogActions>
            </Dialog>

            {/* ================================================================================ */}

            <Dialog
                open={OpenMasterDialog}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return;
                    }
                    setOpenMasterDialog(false)
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Master Input Document
                    <IconButton
                        onClick={() => setOpenMasterDialog(false)}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Document name"
                            type="text"
                            fullWidth
                            {...register('name', { required: 'Name is required' })}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </DialogContent>
                    <Box sx={{m:2}}>
                    {BackendError && <Alert severity='error'>{BackendError?BackendError:null}</Alert>}</Box>
                    <DialogActions>
                        <Button color="warning" onClick={() => { reset(); setOpenMasterDialog(false) }}>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" disabled={Loader}>
                            {Loader ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </DialogActions>
                </form>
               
            </Dialog>

        </>
    )
}

export default ManagerView
