import React, { useEffect, useState } from "react";
import { Alert, Button, LinearProgress, Card, CardContent, TextField, Typography, Box, Select, MenuItem, IconButton, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Paper, FormControl, FormHelperText, styled } from "@mui/material";
import { IconArrowRight, IconFileDiff, IconTrash, IconUserPlus } from "@tabler/icons-react";
import { Container, Delete, FileEdit, Users } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const NewProject = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, trigger, setValue, clearErrors } = useForm();
    const [step, setStep] = useState(1);
    const [stackHolders, setStackHolders] = useState([]);
    const [designationsdata, setdesignationsdata] = useState([])
    const [projectid, setprojectid] = useState()
    const [backenderror, setbackenderror] = useState('')

    const onSubmit = async (data) => {
        try {
            const req = {
                name: data.projectName,
                manager: data.managerEmail,
                description: data.Description
            };
            const response = await axios.post("apiV1/projects/", req);
            console.log(response,'responseresponseresponse');
            
            if (response.status === 201) {
                setStep((prev) => Math.min(prev + 1, 2));
                setprojectid(response.data.data.id)
                setbackenderror('')

            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setbackenderror(error.response.data.message)
        }
    };

    const getdesignations = async () => {
        try {
            const response = await axios.get("apiV1/designations/");
            console.log(response);
            if (response.status === 200) {
                setdesignationsdata(response.data)
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    useEffect(() => {
        getdesignations()
    }, [])

    const handleRemoveStackHolder = (id) => {
        const updatedHolders = stackHolders.filter(holder => holder.id !== id);
        const reassignedHolders = updatedHolders.map((holder, index) => ({ ...holder, id: index }));
        setStackHolders(reassignedHolders);
    };

    const handleAddStackHolder = () => {
        setStackHolders([...stackHolders, { id: stackHolders.length, email: '', designation: '' }]);
    };

    const CreateStacholder = async (data) => {
        const stakeholders = data.stackHolders.map((holder) => ({
            email: holder.email,
            id: holder.designation,
        }));

        const rawdata = stakeholders;

        try {
            const response = await axios.post(`apiV1/projects/${projectid}/add_stakeholder/`, rawdata);
            if (response.status === 200) {
                navigate('/Dashboard');
            }
        } catch (error) {
            console.error('Error generating project:', error.response ? error.response.data : error.message);
            setbackenderror(error.response?.data?.message);
        }
    };
    const onSubmit2 = async (data) => {
        const updatedData = { ...data, stackHolders };
        await CreateStacholder(updatedData);
    };
    const blue = "#7a5afa";
    const Textarea = styled('textarea')(
        ({ theme }) => `
        box-sizing: border-box;
        width: 100%;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
      
        &:hover {
          border-color: ${blue};
        }
      
        &:focus {
          border-color: ${blue};
          box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? blue : blue};
        }
      
        /* Firefox */
        &:focus-visible {
          outline: 0;
        }
      
        /* For multiline textarea */
        min-height: 50px;
        resize: vertical;
        `,
    );
    return (
        <Card
            sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px", alignItems: "center" }}
        >
            <CardContent sx={{ p: 5, maxWidth: "700px", width: "100%" }}>
                <Box sx={{ width: "100%", mb: 3 }}>
                    <LinearProgress
                        variant="determinate"
                        value={step === 1 ? 50 : 100}
                        sx={{
                            height: 10,
                            borderRadius: 5,
                            background: "#d9d9d9",
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: step === 1 ? "#7a5afa" : "#7C3AED",
                            },
                        }}
                    />
                </Box>
                {step === 1 ? (
                    <>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 4 }}>
                            <Typography variant="h4">Create Your Project</Typography>
                            <FileEdit size={24} color="#7C3AED" />
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ mb: 1 }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                >
                                    Project Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter Project Name"
                                    {...register("projectName", {
                                        required: "Project Name is required",
                                        maxLength: {
                                            value: 50,
                                            message: "Project Name must be less than 50 characters ",
                                        },
                                    })}

                                    error={!!errors.projectName}
                                    helperText={errors.projectName?.message}
                                />
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                >
                                    Manager
                                </Typography>
                                <TextField
                                    fullWidth
                                    placeholder="manager@company.com"
                                    {...register("managerEmail", {
                                        required: "Manager Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Invalid email address",
                                        },
                                    })}
                                    error={!!errors.managerEmail}
                                    helperText={errors.managerEmail?.message}
                                />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 500, mb: 1, display: "block" }}
                                >
                                    Description
                                </Typography>
                                <Textarea
                                    {...register("Description", {
                                        required: "Description is required",
                                        maxLength: {
                                            value: 100,
                                            message: "Description cannot exceed 100 characters",
                                        },
                                    })}
                                    placeholder="Description About The Project"
                                    minRows={2}
                                />

                                {errors.Description && (
                                    <Typography color="error" sx={{ fontSize: "0.9rem" }}>
                                        {errors.Description.message}
                                    </Typography>
                                )}
                                <Alert severity="warning" sx={{ mt: 1, borderRadius: 2 }}>
                                    Warning: Access is strictly limited to managers
                                </Alert>
                            </Box>
                            {backenderror && <Alert severity="error">{backenderror ? backenderror : null}</Alert>}
                            <Button type="submit" variant="contained" fullWidth size="large">
                                Next <IconArrowRight />
                            </Button>
                        </form>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box display="flex" justifyContent="space-between" mb={4}>
                                <Typography variant="h5" display="flex" alignItems="center" gap={2}>
                                    <Users size={24} />
                                    Add Stake Holders
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleAddStackHolder}
                                    sx={{ bgcolor: "#7a5af9" }}
                                >
                                    + Stake Holder
                                </Button>
                            </Box>
                            <form onSubmit={handleSubmit(onSubmit2)}>
                                <Box sx={{ overflow: 'auto', height: 400 }}>
                                    {stackHolders.length > 0 && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 610 }} aria-label="simple table">
                                                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Stake Holders Email</TableCell>
                                                        <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Designation</TableCell>
                                                        <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {stackHolders.map((holder, index) => (
                                                        <TableRow key={holder.id}>
                                                            <TableCell component="th" scope="row">
                                                                <TextField
                                                                    fullWidth
                                                                    placeholder="Stake Holder Email"
                                                                    {...register(`stackHolders[${index}].email`, { required: "Email is required" })}
                                                                    error={!!errors?.stackHolders?.[index]?.email}
                                                                    helperText={errors?.stackHolders?.[index]?.email?.message}
                                                                    value={holder.email}
                                                                    onChange={(e) => {
                                                                        const updatedHolders = [...stackHolders];
                                                                        updatedHolders[index].email = e.target.value;
                                                                        setStackHolders(updatedHolders);
                                                                        clearErrors(`stackHolders[${index}].email`)
                                                                    }}

                                                                />

                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <FormControl fullWidth className="departmentDropdown mt-2" error={!!errors?.stackHolders?.[index]?.designation}>
                                                                    <Select
                                                                        {...register(`stackHolders[${index}].designation`, { required: "Designation is required" })}
                                                                        labelId="department-label"
                                                                        displayEmpty
                                                                        sx={{ borderRadius: '8px', height: '3rem' }}
                                                                        defaultValue={holder.designation} // To ensure the initial value is set
                                                                        onChange={(e) => {
                                                                            const updatedHolders = [...stackHolders];
                                                                            updatedHolders[index].designation = e.target.value;
                                                                            setStackHolders(updatedHolders);
                                                                            clearErrors(`stackHolders[${index}].designation`);
                                                                        }}
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            Designation
                                                                        </MenuItem>
                                                                        {designationsdata.map((item) => (
                                                                            <MenuItem key={item.id} value={item.id}>
                                                                                {item.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    <FormHelperText>
                                                                        {errors?.stackHolders?.[index]?.designation?.message}
                                                                    </FormHelperText>
                                                                </FormControl>

                                                            </TableCell>

                                                            <TableCell component="th" scope="row">
                                                                <IconButton onClick={() => handleRemoveStackHolder(holder.id)} color="error">
                                                                    <IconTrash />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Box>
                                <Box sx={{ mb: 2 }}>

                                    {backenderror && <Alert severity="error">{backenderror ? backenderror : null}</Alert>}
                                </Box>
                                {stackHolders.length > 0 && (
                                    <Box sx={{ alignItems: "center" }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="small"
                                            sx={{ gap: 1 }}
                                            fullWidth
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                )}
                            </form>
                        </Box>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default NewProject;
